/**
 * Created by mapbox on 10/06/15.
 */
var id = $('input[name=id]').val();
var amt_decimal = $('input[name=amt_decimal]').val();
var qty_decimal = $('input[name=qty_decimal]').val();
var comm_pct_decimal = $('input[name=comm_pct_decimal]').val();
var comm_amt_decimal = $('input[name=comm_amt_decimal]').val();
var disc_pct_decimal = $('input[name=disc_pct_decimal]').val();
var disc_amt_decimal = $('input[name=disc_amt_decimal]').val();
var datepicker_format = $('input[name=datepicker_format]').val();
var _token = $('input[name=_token]').val();
var def = '';


$(".btn-mredirect")
	.click(function(){
        mredirect(this.value)
	});

$('.flash5').delay(5000).slideUp(300);


function mredirect(val){
    $("#id_mredirect").val(val);
    submit();
}

function submit(){
    document.adminform.submit();
}

$('.btn-loading').on(ace.click_event, function () {
	$('.btn-loading').button('loading');
});


function redirect(url,win_name){
    if (win_name){
        var win = window.open(url,win_name);
        win.focus();
    }else{
        location.href = url;
    }
}

function removeCurrencyFormat(val){
	return val.replace(/[^0-9\.]+/g,"");
}

function percentToRGB(percent) {
	/*
	if (percent === 100) {
		percent = 99
	}
	var r, g, b;

	if (percent < 50) {
		// green to yellow
		r = Math.floor(255 * (percent / 50));
		g = 255;

	} else {
		// yellow to red
		r = 255;
		g = Math.floor(255 * ((50 - percent % 50) / 50));
	}
	b = 0;

	return "rgb(" + r + "," + g + "," + b + ")";
	*/
	value = percent / 100;
	var hue=((1-value)*120).toString(10);
	return ["hsl(",hue,",100%,50%)"].join("");
}

/**
 *  this function handle error return from datatables.
 * @param reason
 */
function errorHandler(reason){
	switch (reason.responseText){
		case 'Unauthorized.':
			jAlert('Your session expired.<br />Login required.','Session Expired',function(){ window.location.reload(); });
			break;
	}
}

/**
 *  gritter message
 */
function gError(msg,title){
	jQuery.gritter.add({
		title: title?title:'Opss... Error!',
		text: msg,
		sticky: false,
		time: '',
		before_open: function(){
			if($('.gritter-item-wrapper').length >= 3)
			{
				return false;
			}
		},
		class_name: 'gritter-error'
	});
}

function validate(){
	var ret = true;
	var validator = $("#adminform").validate({
		errorElement: 'div',
		errorClass: 'help-block',
		focusInvalid: false,
		ignore: "",
		onfocusout: function(element){
			this.element(element);
		},
		rules:{
		},
		messages: {
		},
		highlight: function (e) {
			$(e).closest('.form-group').removeClass('has-info').addClass('has-error');
		},

		success: function (e) {
			$(e).closest('.form-group').removeClass('has-error').addClass('has-info');
			$(e).remove();
		},

		errorPlacement: function (error, element) {
			error.insertAfter(element.parent());
		}
	});
	ret = validator.form();
	//ret = false;
	if (!ret) gError('Field(s) required.');
	return ret;
}

//  onload
$(document).ready(function(){
	$(".date-picker")
		.datepicker({
			autoclose: true,
			todayHighlight: true,
			format: datepicker_format
		})
		//show datepicker when clicking on the icon
		.next().on(ace.click_event, function(){
			$(this).prev().focus();
		});
	$(".date-picker")
		.datepicker({
			autoclose: true,
			todayHighlight: true,
			format: datepicker_format
		})
		.prev().on(ace.click_event, function(){
			$(this).next().focus();
		});

	$(".btn-new").click(function(){
		$('.btn-load').attr('disabled',true);
		$(this).button('loading');
	});
	$(".btn-cancel").click(function(){
		$(".btn-load").attr('disabled',true);
		$(this).button('loading');
	});

	$(".select2").select2();
	autosize($('.autosize'));

	$('.name-limited').inputlimiter({
		limit: 250,
		boxId: 'nameLimitText',
		boxAttach: false,
		remText: '%n character%s remaining...',
		limitText: 'max allowed : %n.',
		breakLine: false
	});

	$('[data-rel=tooltip]').tooltip();
});



/*
 <a href="posts/2" data-method="delete"> <---- We want to send an HTTP DELETE request
 - Or, request confirmation in the process -
 <a href="posts/2" data-method="delete" data-confirm="Are you sure?">
 */

(function() {

	var laravel = {
		initialize: function() {
			this.methodLinks = $('a[data-method]');

			this.registerEvents();
		},

		registerEvents: function() {
			this.methodLinks.on('click', this.handleMethod);
		},

		handleMethod: function(e) {
			var link = $(this);
			var httpMethod = link.data('method').toUpperCase();
			var form;

			// If the data-method attribute is not PUT or DELETE,
			// then we don't know what to do. Just ignore.
			if ( $.inArray(httpMethod, ['PUT', 'DELETE']) === - 1 ) {
				return;
			}

			// Allow user to optionally provide data-confirm="Are you sure?"
			if ( link.data('confirm') ) {
				if ( ! laravel.verifyConfirm(link) ) {
					return false;
				}
			}

			form = laravel.createForm(link);
			form.submit();

			e.preventDefault();
		},

		verifyConfirm: function(link) {
			return confirm(link.data('confirm'));
		},

		createForm: function(link) {
			var form =
				$('<form>', {
					'method': 'POST',
					'action': link.attr('href')
				});

			var token =
				$('<input>', {
					'type': 'hidden',
					'name': '_token',
					'value': $('meta[name="csrf_token"]').attr('content')
				});

			var hiddenInput =
				$('<input>', {
					'name': '_method',
					'type': 'hidden',
					'value': link.data('method')
				});

			return form.append(token, hiddenInput)
				.appendTo('body');
		}
	};

	laravel.initialize();

})();